import Component from '../../../scripts/modules/component.js'

export default class PosterAccordeonComponent extends Component {
  init() {
    this.containers = [...this.element.querySelectorAll('.poster-accordeon__poster-container')]

    for (const container of this.containers) {
      container.addEventListener('click', event => this.focusContainer(event))
    }
  }

  focusContainer(event) {
    event.preventDefault()

    if (event.target.classList.contains('poster-accordeon__poster-container--expanded')) {
      event.target.classList.remove('poster-accordeon__poster-container--expanded')
      this.element.classList.remove('poster-accordeon--expanded')
    } else {
      for (const container of this.containers) {
        container.classList.remove('poster-accordeon__poster-container--expanded')
      }

      event.target.classList.add('poster-accordeon__poster-container--expanded')
      this.element.classList.add('poster-accordeon--expanded')
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.poster-accordeon')) {
    element.instance = element.instance || new PosterAccordeonComponent(element)
  }
})
