import Component from '../../../scripts/modules/component.js'

export default class BackgroundVideoComponent extends Component {
  init() {
    this.loadObserver = null
    this.video = this.element.querySelector('video')

    if (this.element.getAttribute('data-lazyload') === null || !this.video) {
      return
    }

    this.element.removeAttribute('data-lazyload')

    this.element.forceLoad = this.forceLoad.bind(this)
    this.element.forcePlay = this.forcePlay.bind(this)
    this.element.forcePause = this.forcePause.bind(this)
    this.element.disconnectObservers = this.disconnectObservers.bind(this)

    // Set observers on next paint (otherwise it'll cause a forced layout reflow)
    window.requestAnimationFrame(() => this.attachLoadObserver())
  }

  destroy() {
    this.disconnectLoadObserver()

    this.element.forceLoad = function () {}
  }

  disconnectObservers() {
    this.disconnectLoadObserver()
  }

  attachLoadObserver() {
    if (this.loadObserver) {
      this.disconnectLoadObserver()
    }

    // Start halfway outside the viewport
    this.loadObserver = new window.IntersectionObserver(this.onLoadIntersect.bind(this), { rootMargin: `${window.innerHeight * 2}px 0px` })
    this.loadObserver.observe(this.element)
  }

  disconnectLoadObserver() {
    if (!this.loadObserver) {
      return
    }

    this.loadObserver.unobserve(this.element)
    this.loadObserver.disconnect()
    this.loadObserver = null
  }

  onLoadIntersect(changes = []) {
    for (const change of changes) {
      if (change.intersectionRatio) {
        this.loadVideo()
        this.disconnectLoadObserver()
        this.destroy()

        break
      }
    }
  }

  loadVideo() {
    this.element.classList.add('background-video--loading')
    this.video.setAttribute('poster', this.video.getAttribute('data-poster'))
    this.video.removeAttribute('data-poster')

    // Possible leak? requestAnimationFrame doesn't put it in the queue
    window.setTimeout(() => {
      this.element.classList.remove('background-video--loading')
      this.video.autoplay = true
      this.video.play()
    }, 0)
  }

  forceLoad() {
    this.loadVideo()
    this.disconnectLoadObserver()
    this.destroy()
  }

  forcePlay() {
    if (!this.video) {
      return
    }

    if (this.video.paused) {
      this.video.play()
    }
  }

  forcePause() {
    if (!this.video) {
      return
    }

    if (!this.video.paused) {
      this.video.pause()
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.background-video')) {
    element.instance = element.instance || new BackgroundVideoComponent(element)
  }
})
