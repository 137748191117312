import Component from '../../../scripts/modules/component.js'

export default class ButtonComponent extends Component {
  init() {
    this.element.addEventListener('click', event => window.dispatchEvent(new CustomEvent('button:clicked', { detail: { element: this.element, originalEvent: event } })))
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.button')) {
    element.instance = element.instance || new ButtonComponent(element)
  }
})
