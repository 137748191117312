import Component from '../../../scripts/modules/component.js'

export default class CardComponent extends Component {
  init() {
    this.cardAnchor = this.element.querySelector('.card__anchor')
    this.cardAnchor?.addEventListener('click', () => {
      window.dispatchEvent(new CustomEvent('card:clicked', { details: { element: this.element } }))
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.card')) {
    element.instance = element.instance || new CardComponent(element)
  }
})
