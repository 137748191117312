import serialize from 'form-serialize'

// Propagator of events based on instructions by Maxlead for Centric.

const dataLayer = window.dataLayer
const pageType = dataLayer && dataLayer.length && dataLayer[0] && dataLayer[0].page ? dataLayer[0].page.type : ''

// helpers
const vacancyOverviews = ['vacancy overview', 'student overview', 'freelance overview']
const vacancyDetails = ['vacancy detail', 'student detail', 'freelance detail']

function findOptionName(fieldName, optionValue) {
  const option = document.querySelector(`[for="id_${fieldName}_${optionValue}"]`)
  if (option) {
    return option.innerText
  }
  return optionValue
}

function getSearchCategory() {
  if (vacancyOverviews.includes(pageType)) {
    return getVacancyType() + ' search'
  }
}

function getVacancyType() {
  if (vacancyOverviews.includes(pageType)) {
    return {
      'vacancy overview': 'vacancy',
      'freelance overview': 'freelance',
      'student overview': 'student',
    }[pageType]
  }
  if (vacancyDetails.includes(pageType)) {
    return {
      'vacancy detail': 'vacancy',
      'freelance detail': 'freelance',
      'student detail': 'student',
    }[pageType]
  }
}

function buildFilterConfiguration(serializedFormData) {
  if (serializedFormData === undefined) {
    serializedFormData = serialize(document.querySelector('.filter-bar form'))
  }
  const params = serializedFormData.split('&')
  let query = ''
  const filters = {}
  params.forEach(param => {
    const [key, value] = param.split('=')
    if (key === 'q') {
      query = value
    } else if (key !== 'p') {
      filters[key] = (filters[key] || []).concat(value.split(',').map(subvalue => findOptionName(key, subvalue).replace(',', '')))
    }
  })
  const filtersOutput = Object.entries(filters).map(([key, value]) => `${key}:${value.join(',')}`)
  if (!query && !Object.entries(filtersOutput).length) {
    return 'all results'
  }
  return `q:${query}|${filtersOutput.join('|')}`
}

/**
 * Send event to dataLayer
 * @param event
 * @param eventCategory
 * @param eventAction
 * @param eventLabel
 * @param eventValue
 * @param eventNonInteraction
 */
function sendEvent(event, eventCategory, eventAction, eventLabel, eventValue, eventNonInteraction) {
  dataLayer.push({
    event,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    eventNonInteraction,
  })
}

function sendOfferEvent(event, offer, eventLabel, eventValue, eventNonInteraction) {
  dataLayer.push({
    event,
    offer,
    eventLabel,
    eventValue,
    eventNonInteraction,
  })
}

function sendCustomEvent(event) {
  dataLayer.push(event)
}

// 4.1.1. Spotler/MarketingDynamics submit

function seoFormSubmitHandler(event) {
  const element = event.detail.element
  const emailFormElement = event.detail.form.querySelector("input[type='email']")

  let isMarketingDynamicsWithEmailFormInput = false
  if (emailFormElement) {
    if (emailFormElement.closest('.text-header-form--integration-marketingdynamics') || emailFormElement.closest('.skinned-content--integration-marketingdynamics') || emailFormElement.closest('.skinned-content-deluxe--integration-marketingdynamics')) {
      isMarketingDynamicsWithEmailFormInput = true
    }
  }

  if (!isMarketingDynamicsWithEmailFormInput) {
    let eventAction = ''
    let eventLabel = ''
    if (element && element.getAttribute('data-maxlead-action')) {
      eventAction = element.getAttribute('data-maxlead-action')
      eventLabel = element.getAttribute('data-maxlead-label')
    }
    if (element && element.getAttribute('data-datalayer-bu-action')) {
      sendCustomEvent({
        event: 'formSubmit',
        eventCategory: element.getAttribute('data-datalayer-bu-action'),
        eventAction,
        eventLabel,
        eventValue: undefined,
        eventNonInteraction: false,
      })
    } else {
      if (eventAction) {
        sendEvent('formSubmit', undefined, eventAction, eventLabel, undefined, false)
      }
    }
  }
}
window.addEventListener('skinned-content:form-submit', seoFormSubmitHandler)
window.addEventListener('text-header-form:form-submit', seoFormSubmitHandler)

// 4.1.2. Home Page Search Used
const homeSearchForm = document.querySelector('[data-role=home-expertise-search]')
if (homeSearchForm) {
  homeSearchForm.addEventListener('submit', () => {
    const expertiseField = homeSearchForm.querySelector('[name=expertises]')
    const locationField = homeSearchForm.querySelector('[name=provinces]')
    const expertise = expertiseField.options[expertiseField.selectedIndex].innerText
    const location = locationField.options[locationField.selectedIndex].innerText
    sendEvent('eventPush', 'homeSearch', expertise, location, undefined, false)
  })
}

// 4.1.3. Vacancy/Freelance/Student Filter Click
window.addEventListener('filter-bar:toggled', event => {
  if (vacancyOverviews.includes(pageType)) {
    const eventCategory = getSearchCategory()
    const eventAction = {
      open: 'filter menu opened',
      closed: 'filter menu closed',
    }[event.detail.newState]
    sendEvent('eventPush', eventCategory, eventAction, undefined, undefined, false)
  }
})

// 4.1.4. Results Shown
window.addEventListener('filter-bar:data-updated', event => {
  const data = event.detail.data
  // start === 0 means we are only interested in new searches, not in additional pages of existing
  // searches
  if (vacancyOverviews.includes(pageType) && data.start === 0) {
    const eventCategory = getSearchCategory()
    const eventAction = `${data.total} results shown`
    const eventLabel = buildFilterConfiguration(data.formData)
    sendEvent('eventPush', eventCategory, eventAction, eventLabel, undefined, false)
  }
})

// 4.1.5. Load More Results Click
window.addEventListener('filter-bar:data-updated', event => {
  const data = event.detail.data
  // start > 0 means we are only interested in show more searches
  if (vacancyOverviews.includes(pageType) && data.start > 0) {
    const eventCategory = getSearchCategory()
    const eventLabel = buildFilterConfiguration(data.formData)
    sendEvent('eventPush', eventCategory, 'more results click', eventLabel, undefined, false)
  }
})

// 4.1.6. Vacancy/Freelance/Student Click
window.addEventListener('card:clicked', event => {
  if (vacancyOverviews.includes(pageType)) {
    const element = event.detail.element
    const offer = {
      name: element.getAttribute('data-name'),
      id: element.getAttribute('data-id'),
      expertise: getVacancyType(),
      region: element.getAttribute('data-region'),
      location: element.getAttribute('data-location'),
      orgLvl1: element.getAttribute('data-expertise').split(',')[0],
      orgLvl2: element.getAttribute('data-expertise').split(',').reverse()[0],
      discipline: element.getAttribute('data-expertise').split(',')[0],
      hours: element.getAttribute('data-hours'),
      level: element.getAttribute('data-level'),
    }
    sendOfferEvent('offerClick', offer, buildFilterConfiguration(), undefined, false)
  }
})

// 4.1.8. Vacancy/Freelance/Student Apply Click
window.addEventListener('button:clicked', event => {
  if (vacancyDetails.includes(pageType) && event.detail.element.getAttribute('data-role') === 'vacancy-apply') {
    sendCustomEvent({ event: 'applyClick' })
  }
})

// 4.1.10. Form Submit - CMS Form
window.addEventListener('form:submitted', event => {
  if (vacancyDetails.includes(pageType) && event.detail.element.getAttribute('data-role') === 'apply-form') {
    const offer = window.MAXLEAD_OFFER
    sendCustomEvent({
      event: 'cmsFormSubmit',
      offer,
      eventCategory: 'cms form submit',
      eventAction: `${getVacancyType()} application`,
      eventLabel: offer.name,
      eventValue: undefined,
      eventNonInteraction: false,
    })
  }
})

// 4.1.11. Form Submit - CMS Form - Open Application
// --> This is an iFrame
