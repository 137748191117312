// Automatically set the target to '_blank' for external links.
export default function setAllExternalLinksToTargetBlank() {
  const elements = document.querySelectorAll('a')

  for (const element of elements) {
    if (!element.classList.contains('no-ext') && element.href.substring(0, 4) === 'http' && !new RegExp('/' + window.location.host + '/').test(element.href)) {
      if (!element.target) {
        element.target = '_blank'
      }

      element.className = (element.className + ' is-external').trim()

      // Prevent TabNabbing; see: https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
      // element.rel = (element.rel + ' noopener noreferrer').trim();
    }
  }
}
