import Component from '../../../scripts/modules/component.js'
import SwiperWrapper from '../../../scripts/modules/swiper-wrapper.js'

const SWIPER_OPTIONS = {
  spaceBetween: 16,
  breakpointsInverse: true,
  breakpoints: {
    530: { slidesPerView: 2 },
    800: { slidesPerView: 3 },
    1180: { slidesPerView: 3 },
    1480: { slidesPerView: 3 },
  },
  watchOverflow: true,
}

export default class CardCarouselComponent extends Component {
  async init() {
    this.container = this.element.querySelector('.swiper-container')
    this.swiperWrapper = new SwiperWrapper(this.container, SWIPER_OPTIONS, [])
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.card-carousel')) {
    element.instance = element.instance || new CardCarouselComponent(element)
  }
})
