import Component from '../../../scripts/modules/component.js'

export default class BackgroundImageComponent extends Component {
  init() {
    this.observer = null
    this.style = this.element.parentNode.querySelector('style')

    if (this.element.getAttribute('data-lazyload') === null || !this.style) {
      return
    }

    this.element.removeAttribute('data-lazyload')
    this.element.forceLoad = this.forceLoad.bind(this)
    this.element.addEventListener('force-load', () => this.forceLoad())
    this.element.disconnectObservers = this.disconnectObservers.bind(this)

    // Set observers on next paint (otherwise it'll cause a forced layout reflow)
    window.requestAnimationFrame(() => this.attachObserver())
  }

  destroy() {
    this.style = null
    this.observer = null

    this.element.forceLoad = function () {}
  }

  disconnectObservers() {
    this.disconnectObserver()
  }

  attachObserver() {
    if (this.observer) {
      this.disconnectObserver()
    }

    // Start halfway outside the viewport
    this.observer = new window.IntersectionObserver(this.onIntersect.bind(this), { rootMargin: `${window.innerHeight * 2}px 0px` })
    this.observer.observe(this.element)
  }

  disconnectObserver() {
    if (!this.observer) {
      return
    }

    this.observer.unobserve(this.element)
    this.observer.disconnect()

    this.observer = null
  }

  onIntersect(changes = []) {
    for (const change of changes) {
      if (change.intersectionRatio) {
        this.loadImage()
        this.disconnectObserver()
        this.destroy()

        break
      }
    }
  }

  loadImage() {
    this.element.classList.add('background-image--loading')
    this.parseStyle()

    // Possible leak? requestAnimationFrame doesn't put it in the queue
    window.setTimeout(() => this.element.classList.remove('background-image--loading'), 0)
  }

  parseStyle() {
    this.style.innerHTML = this.style.innerHTML.replace(/font-family: '(.*?)'/gi, 'background-image: url($1)')
  }

  forceLoad() {
    this.loadImage()
    this.disconnectObserver()
    this.destroy()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.background-image')) {
    element.instance = element.instance || new BackgroundImageComponent(element)
  }
})
