import Component from '../../../scripts/modules/component.js'

export default class ArticleCookiesComponent extends Component {
  init() {
    this.buttonChange = this.element.querySelector('.button--change')
    this.buttonChange?.addEventListener('click', event => {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent('show-cookie-details'))
    })

    this.buttonClear = this.element.querySelector('.button--clear')
    this.buttonClear?.addEventListener('click', event => {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent('show-cookie-settings'))
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.article-cookies')) {
    element.instance = element.instance || new ArticleCookiesComponent(element)
  }
})
