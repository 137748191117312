// Take pre-existing classes
const classes = Array.from(document.documentElement.classList)

// Enable JS className
classes.push('has-js')

// Enable hover(), focus() and hover-and-focus() mixins
classes.push('ontouchstart' in window ? 'touch' : 'no-touch')

// Enable iOS classnames (is-ipad, is-iphone, is-ios)
if (navigator.userAgent.indexOf('iPad') !== -1) {
  classes.push('is-ipad')
  classes.push('is-ios')
} else if (navigator.userAgent.indexOf('iPhone') !== -1) {
  classes.push('is-iphone')
  classes.push('is-ios')
}

// Enable OS classnames (is-osx, is-windows)
if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
  classes.push('is-osx')
} else if (navigator.userAgent.indexOf('Windows') !== -1) {
  classes.push('is-windows')
}

// Enable Android classNames (is-android)
if (navigator.userAgent.indexOf('Android') !== -1) {
  classes.push('is-android')
}

// Apply classes in one go to prevent performance issues (yes, really)
document.documentElement.className = classes.join(' ')

// Prevent transitions on load (makes the page load in a really weird way)
const element = document.documentElement

element.classList.add('is-loading')
window.addEventListener('load', () => window.requestAnimationFrame(() => element.classList.remove('is-loading')))
