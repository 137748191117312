import Component from '../../../scripts/modules/component.js'

const COOKIE_NAME_LANGUAGE = 'language-selected'
const COOKIE_NAME_CONSENT = 'cookies-accepted-new'

function getCookie(name) {
  const parts = `; ${document.cookie}`.split(`; ${name}=`)

  return parts.length < 2 ? undefined : parts.pop().split(';').shift()
}

function setCookie(name, value, expiryDays, domain, path, secure) {
  const expiryDate = new Date(new Date().setDate(new Date().getDate() + (expiryDays || 365))).toUTCString()
  const cookie = [`${name}=${value}`]

  cookie.push(`expires=${expiryDate}`)
  cookie.push(`path=${path || '/'}`)

  if (domain) {
    cookie.push(`domain=${domain}`)
  }

  if (secure) {
    cookie.push('secure')
  }

  document.cookie = cookie.join(';')
}

export default class CookieBarComponent extends Component {
  init() {
    const cookie = (getCookie(COOKIE_NAME_LANGUAGE) || '').trim()

    this.bar = this.element
    this.buttonAccept = this.element.querySelector('.button--accept')

    if (!cookie) {
      this.showBar()
    }

    this.addEventListeners()
  }

  addEventListeners() {
    this.buttonAccept.addEventListener('click', event => this.onButtonChangeClick(event))
  }

  onButtonChangeClick(event) {
    event.preventDefault()
    this.hideBar()

    if (!getCookie(COOKIE_NAME_CONSENT)) {
      // can't set cookie
    } else {
      // store language preference
      setCookie(COOKIE_NAME_LANGUAGE, 'true')
    }
  }

  showBar() {
    window.requestAnimationFrame(() => this.bar.classList.add('language-switch-bar--visible'))
  }

  hideBar() {
    window.requestAnimationFrame(() => this.bar.classList.remove('language-switch-bar--visible'))
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.language-switch-bar')) {
    element.instance = element.instance || new CookieBarComponent(element)
  }
})
