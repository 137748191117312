import FilterBarObserver from '../../../scripts/components/filter-bar-observer.js'
import BackgroundImageComponent from '../../atoms/background-image/background-image.js'

export default class EventGridComponent extends FilterBarObserver {
  init() {
    super.init()
  }

  onFilterBarResults(event) {
    super.onFilterBarResults(event)

    for (const element of this.element.querySelectorAll('.background-image')) {
      element.instance = element.instance || new BackgroundImageComponent(element)
    }
  }

  insertNewItem(itemHTML) {
    const newElement = document.createElement('div')
    const classes = ['event-grid__panel-container']

    newElement.setAttribute('class', classes.join(' '))

    newElement.innerHTML = itemHTML
    this.elementItemsContainer.appendChild(newElement)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.event-grid')) {
    element.instance = element.instance || new EventGridComponent(element)
  }
})
