import fetchJSONP from 'fetch-jsonp'

import Component from '../../../scripts/modules/component.js'

export default class ArticleGistComponent extends Component {
  init() {
    this.observer = null
    this.data = null
    this.wrapper = this.element.querySelector('.article-gist__wrapper')

    if (this.element.getAttribute('data-lazyload') === null) {
      return
    }

    if (typeof window.Cookiebot === 'undefined' || !window.Cookiebot || typeof window.Cookiebot.consent === 'undefined' || !window.Cookiebot.consent || !window.Cookiebot.consent.marketing) {
      this.element.classList.add('article-gist--cookies-not-accepted')
      window.addEventListener('CookiebotOnAccept', () => this.initGist())
      return
    }

    this.initGist()
  }

  initGist() {
    if (typeof window.Cookiebot === 'undefined' || !window.Cookiebot || typeof window.Cookiebot.consent === 'undefined' || !window.Cookiebot.consent || !window.Cookiebot.consent.marketing) {
      return
    }

    this.element.classList.remove('article-gist--cookies-not-accepted')
    this.element.removeAttribute('data-lazyload')
    this.element.forceLoad = this.forceLoad.bind(this)
    this.element.addEventListener('force-load', () => this.forceLoad())
    this.element.disconnectObservers = this.disconnectObservers.bind(this)

    // Set observers on next paint (otherwise it'll cause a forced layout reflow)
    window.requestAnimationFrame(() => this.attachObserver())
  }

  destroy() {
    this.observer = null
    this.element.forceLoad = function () {}
  }

  disconnectObservers() {
    this.disconnectObserver()
  }

  attachObserver() {
    if (this.observer) {
      this.disconnectObserver()
    }

    // Start halfway outside the viewport
    this.observer = new window.IntersectionObserver(this.onIntersect.bind(this), { rootMargin: `${window.innerHeight * 2}px 0px` })
    this.observer.observe(this.element)
  }

  disconnectObserver() {
    if (!this.observer) {
      return
    }

    this.observer.unobserve(this.element)
    this.observer.disconnect()
    this.observer = null
  }

  onIntersect(changes = []) {
    for (const change of changes) {
      if (change.intersectionRatio) {
        this.loadGist()
        this.disconnectObserver()
        this.destroy()

        break
      }
    }
  }

  async loadGist() {
    const url = this.element.getAttribute('data-url')

    if (!url || url.indexOf('gist.github.com') === -1) {
      return
    }

    const regex = /^(.+:\/\/)(gist\.github\.com\/)(.+)[/]([\dabcdef]+).*$/gim // Match protocol, url, user, and hash (until the dot).
    const matches = regex.exec(url)

    this.element.removeAttribute('data-url')
    this.element.classList.add('article-gist--loading')

    try {
      const data = await fetchJSONP(`https://gist.github.com/${matches[4]}.json`, { method: 'GET' }).then(response => response.json())
      this.insertGist(data)
    } catch {
      this.element.classList.remove('article-gist--loading')
      this.element.classList.add('article-gist--error')
    }
  }

  forceLoad() {
    this.loadGist()
    this.disconnectObserver()
    this.destroy()
  }

  insertGist(data) {
    this.wrapper.innerHTML = data.div

    if (document.getElementById('github-gist-css')) {
      return
    }

    const style = document.createElement('link')
    style.id = 'github-gist-css'
    style.rel = 'stylesheet'
    style.href = data.stylesheet

    document.body.appendChild(style)
    this.element.classList.remove('article-gist--loading')
    window.requestAnimationFrame(() => this.element.classList.add('article-gist--loaded'))

    this.disconnectObserver()
    this.destroy()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.article-gist')) {
    element.instance = element.instance || new ArticleGistComponent(element)
  }
})
