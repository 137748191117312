import Component from '../../../scripts/modules/component.js'

export default class FormFieldComponent extends Component {
  init() {
    this.fileInput = this.element.querySelector('input[type="file"]')
    this.fileList = this.element.querySelector('.richtext ul')

    if (this.fileInput) {
      this.initFileInput()
    }
  }

  initFileInput() {
    const buttonRemove = this.element.querySelector('.button--remove')

    this.fileInput.addEventListener('change', event => this.extractFilenames(event))
    buttonRemove.addEventListener('click', event => this.resetFileField(event))
  }

  extractFilenames(event) {
    if (!this.fileList) {
      return
    }

    const filenames = []

    this.fileList.innerHTML = ''

    if (this.fileInput.files && this.fileInput.files.length > 1) {
      if (this.fileInput.files[0].name) {
        const files = [...this.fileInput.files]

        for (const file of files) {
          filenames.push(file.name.split('\\').pop())
        }
      } else {
        filenames.push('...')
      }
    } else if (this.fileInput.value) {
      filenames.push(this.fileInput.value.split('\\').pop())
    } else {
      return
    }

    for (const filename of filenames) {
      const item = document.createElement('li')

      item.className = 'form-field__list-item'
      item.innerText = filename

      this.fileList.appendChild(item)
    }

    if (!filenames.length) {
      this.resetFileField(event)
    } else {
      this.element.classList.add('form-field--has-file')
    }
  }

  resetFileField(event) {
    event.preventDefault()
    event.target.blur()

    this.fileInput.value = ''
    this.element.classList.remove('form-field--has-file')

    if (!this.fileList) {
      return
    }

    this.fileList.innerHTML = ''
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.form-field')) {
    element.instance = element.instance || new FormFieldComponent(element)
  }
})
