import FilterBarObserver from '../../../scripts/components/filter-bar-observer.js'

export default class BrickGridComponent extends FilterBarObserver {
  init() {
    super.init()

    if (this.onShowMoreClick) {
      window.addEventListener('filter-bar:request-load-more', this.onShowMoreClick.bind(this))
    }
  }

  onFilterBarResults(event) {
    super.onFilterBarResults(event)
  }

  insertNewItem(itemHTML) {
    const newElement = document.createElement('div')
    const classes = ['brick-grid__brick-container']

    newElement.setAttribute('class', classes.join(' '))

    newElement.innerHTML = itemHTML
    this.elementItemsContainer.appendChild(newElement)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.brick-grid')) {
    element.instance = element.instance || new BrickGridComponent(element)
  }
})
