import Component from '../../../scripts/modules/component.js'

export default class ArticleCodeComponent extends Component {
  async init() {
    this.pre = this.element.querySelector('.article-code__pre')
    this.code = this.element.querySelector('.article-code__code')
    this.language = this.element.getAttribute('data-language') || 'none'

    if (!this.code) {
      return
    }

    this.codeString = this.code.innerHTML
    this.codeString = this.codeString.trimStart()
    this.codeString = this.codeString.trimEnd()
    this.code.innerHTML = this.codeString

    if (!this.codeString) {
      return
    }

    if (!window.Prism) {
      // eslint-disable-next-line no-unused-expressions
      ;(await import(/* webpackChunkName: "prism-core" */ '../../../scripts/plugins/prism-core.js')).default
      await import(/* webpackChunkName: "prism-languages" */ '../../../scripts/plugins/prism-languages.js')
      window.Prism.manual = true
    }

    window.Prism.highlightElement(this.code)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.article-code')) {
    element.instance = element.instance || new ArticleCodeComponent(element)
  }
})
