import Component from '../../../scripts/modules/component.js'

export default class PictureComponent extends Component {
  init() {
    this.observer = null
    this.picture = this.element.querySelector('.picture__picture')
    this.img = this.element.querySelector('.picture__img')
    this.alt = this.img.alt || ''
    this.sources = [...this.element.querySelectorAll('source')]

    if (this.element.getAttribute('data-lazyload') === null || !this.picture || !this.img || !this.sources) {
      return
    }

    this.element.removeAttribute('data-lazyload')

    // Set observers on next paint (otherwise it'll cause a forced layout reflow)
    window.requestAnimationFrame(() => this.attachObserver())

    this.element.forceLoad = this.forceLoad.bind(this)
  }

  destroy() {
    this.sources = null
    this.img = null
    this.picture = null
    this.observer = null
    this.element.forceLoad = () => {}
  }

  attachObserver() {
    if (this.observer) {
      this.disconnectObserver()
    }

    // Start halfway outside the viewport
    this.observer = new window.IntersectionObserver(this.onIntersect.bind(this), { rootMargin: `${window.innerHeight * 2}px 0px` })
    this.observer.observe(this.img)
  }

  disconnectObserver() {
    if (!this.observer) {
      return
    }

    this.observer.unobserve(this.img)
    this.observer.disconnect()
    this.observer = null
  }

  onIntersect(changes = []) {
    for (const change of changes) {
      if (change.intersectionRatio) {
        this.loadImage()
        this.disconnectObserver()
        this.destroy()

        break
      }
    }
  }

  loadImage() {
    this.element.classList.add('picture--loading')

    // Dirty, but guaranteed to work. Changing <source> props is a bit fiddly
    this.picture.innerHTML = ' '

    this.replaceSources()
    this.replaceImg()

    // Possible leak? requestAnimationFrame doesn't put it in the queue
    window.setTimeout(() => {
      this.element.classList.remove('picture--loading')
    }, 0)
  }

  replaceSources() {
    if (document.documentMode) {
      return // Not for IE11
    }

    for (const source of this.sources) {
      const newSource = document.createElement('source')

      newSource.setAttribute('srcset', source.getAttribute('data-srcset'))

      if (source.getAttribute('media') !== null) {
        newSource.setAttribute('media', source.getAttribute('media'))
      }

      this.picture.appendChild(newSource)
    }
  }

  replaceImg() {
    const newImg = document.createElement('img')

    newImg.setAttribute('class', this.img.getAttribute('class'))
    newImg.src = document.documentMode ? this.img.getAttribute('data-src') : ' ' // Src cannot be empty
    newImg.alt = this.alt

    // Add to DOM and reset reference
    this.img = this.picture.appendChild(newImg)
  }

  forceLoad() {
    if (!this.picture || !this.img) {
      return
    }

    this.loadImage()
    this.disconnectObserver()
    this.destroy()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.picture')) {
    element.instance = element.instance || new PictureComponent(element)
  }
})
