import debounce from 'lodash.debounce'

import Component from '../../../scripts/modules/component.js'

const BREAKPOINT_NOTEBOOK = 1180
const TIMEOUT_DELAY = 350

let timeout

export default class ExpandedMenuBarComponent extends Component {
  init() {
    this.showExpandedMenu = this.element.dataset.showExpanded === 'True'
    this.isStickyMenu = this.element.dataset.isSticky === 'True'

    if (this.isStickyMenu) {
      document.documentElement.classList.add('menu-is-sticky')
    }

    this.toggleOverlayButtons = [...this.element.querySelectorAll('.button--menu')]
    this.menuOverlay = document.getElementById('menu-overlay')
    this.menuLinksContainer = this.element.querySelector('.expanded-menu-bar__menu-links')
    this.submenus = [...this.element.querySelectorAll('.expanded-menu-bar__submenu')]
    this.linksWithSubmenu = [...this.element.querySelectorAll('a[aria-haspopup="true"]:not(.link--back), .button--search, .button--language')]
    this.backLinks = [...this.element.querySelectorAll('.link--back')]
    this.background = this.element.querySelector('.expanded-menu-bar__overlay-background')
    this.searchbox = this.element.querySelector('.form-field--search input')
    this.overlayFocusTraps = {}

    this.initOverlay()
    this.initSubmenus()
    this.initBackground()

    window.addEventListener(
      'resize',
      debounce(() => this.onWindowResize(), 150, { leading: true, trailing: true })
    )
    this.onWindowResize()
  }

  onWindowResize() {
    if (window.innerWidth < BREAKPOINT_NOTEBOOK) {
      this.menuOverlay.setAttribute('role', 'dialog')
      this.menuLinksContainer.setAttribute('role', 'menu')
    } else {
      this.menuOverlay.removeAttribute('role', 'dialog')
      this.menuLinksContainer.setAttribute('role', 'menubar')

      // Force close menu if open on level 0
      if (document.documentElement.classList.contains('overlay-visible') && document.querySelector('.expanded-menu-bar__submenu--visible[data-level="0"]')) {
        this.toggleMenuOverlay()

        window.clearTimeout(timeout)
        timeout = window.setTimeout(() => this.toggleSubmenu, TIMEOUT_DELAY)
      }
    }
  }

  setDocumentClasses() {
    const overlayIsOpen = document.querySelector('.expanded-menu-bar--overlay-visible')
    const submenuIsOpen = document.querySelector('.expanded-menu-bar__submenu--visible')

    document.documentElement.classList.toggle('overlay-visible', overlayIsOpen)
    document.documentElement.classList.toggle('submenu-visible', submenuIsOpen)
    document.documentElement.classList.toggle('prevent-scrolling', overlayIsOpen)
  }

  initOverlay() {
    for (const button of this.toggleOverlayButtons) {
      button.addEventListener('click', event => this.toggleMenuOverlay(event))
    }

    document.addEventListener('keydown', event => this.onDocumentKeyDown(event))
  }

  onDocumentKeyDown(event) {
    if (!this.element.classList.contains('expanded-menu-bar--overlay-visible')) {
      return
    }

    if (event.keyCode === 27) {
      this.toggleMenuOverlay()
    }
  }

  toggleMenuOverlay(event) {
    if (event) {
      event.preventDefault()
    }

    if (!this.isStickyMenu) {
      window.scrollTo(0, 0)
    }

    if (this.element.classList.contains('expanded-menu-bar--overlay-visible')) {
      this.element.classList.remove('expanded-menu-bar--overlay-visible')

      for (const link of this.linksWithSubmenu) {
        link.setAttribute('aria-expanded', false)
      }

      window.clearTimeout(timeout)
      timeout = window.setTimeout(() => this.toggleSubmenu(document.querySelector('.expanded-menu-bar__submenu[data-level="0"]')), TIMEOUT_DELAY)
    } else {
      this.element.classList.add('expanded-menu-bar--overlay-visible')
    }

    this.setDocumentClasses()
  }

  initSubmenus() {
    for (const link of this.linksWithSubmenu) {
      link.setAttribute('aria-expanded', false)
      link.addEventListener('click', event => this.clickLink(link, event))
    }

    for (const link of this.backLinks) {
      link.addEventListener('click', event => this.clickLink(link, event))
    }
  }

  collapseMenu() {
    const container = document.querySelector('.expanded-menu-bar__overlay-content')

    container.classList.remove('expanded-menu-bar__overlay-content__is-expanded')

    if (container.querySelector('.expanded-menu-bar__overlay-content__is-expanded-wrapper')) {
      const columns = [...this.element.querySelectorAll('.expanded-menu-bar__submenu-links-column-placeholder')]

      for (const column of columns) {
        column.classList.add('expanded-menu-bar__submenu-links-column')
        column.classList.remove('expanded-menu-bar__submenu-links-column-placeholder')
      }

      container.querySelector('.expanded-menu-bar__overlay-content__is-expanded-wrapper').classList.remove('expanded-menu-bar__overlay-content__is-expanded-wrapper')
    }

    const subMenuItems = [...this.element.querySelectorAll('.expanded-menu-bar__submenu')]

    for (const menuitem of subMenuItems) {
      menuitem.classList.remove('show-as-expanded')
    }
  }

  expandMenu(slug) {
    if (window.innerWidth < BREAKPOINT_NOTEBOOK) {
      this.collapseMenu(slug)
      return
    }

    const parent = slug.dataset.slug
    const subMenuItems = [...this.element.querySelectorAll('.expanded-menu-bar__submenu')]

    let hasExpandedMenu = false

    for (const menuitem of subMenuItems) {
      if (menuitem.dataset.parent === parent && menuitem.dataset.level === '2') {
        menuitem.classList.add('show-as-expanded')
        hasExpandedMenu = true
      } else if (menuitem.dataset.level !== '1') {
        menuitem.classList.remove('show-as-expanded')
      }
    }

    if (hasExpandedMenu) {
      const columns = [...this.element.querySelectorAll('.expanded-menu-bar__submenu-links-column')]

      for (const column of columns) {
        column.classList.add('expanded-menu-bar__submenu-links-column-placeholder')
        column.classList.remove('expanded-menu-bar__submenu-links-column')
      }

      const container = document.querySelector('.expanded-menu-bar__overlay-content')

      container.classList.add('expanded-menu-bar__overlay-content__is-expanded')

      if (!container.querySelector('.expanded-menu-bar__overlay-content__is-expanded-wrapper')) {
        const wrapper = document.createElement('div')

        wrapper.classList.add('expanded-menu-bar__overlay-content__is-expanded-wrapper')

        const toBeWrapped = document.querySelectorAll('.expanded-menu-bar__submenu')

        for (let t = 0; t < toBeWrapped.length; t++) {
          wrapper.appendChild(toBeWrapped[t])
        }

        container.appendChild(wrapper)
      }
    }
  }

  clickLink(link, event) {
    const slug = link.getAttribute('data-slug')
    const submenu = document.querySelector(`#submenu-${slug}`)

    if (!submenu) {
      return
    }

    if (event) {
      event.preventDefault()
    }

    if (this.showExpandedMenu) {
      this.collapseMenu()
      this.expandMenu(link)
    }

    if (!this.element.classList.contains('expanded-menu-bar--overlay-visible')) {
      this.toggleMenuOverlay() // Force opening the menu on desktop
      this.toggleSubmenu(submenu, link)
    } else {
      const isLevel1 = parseInt(link.getAttribute('data-level'), 10) === 1
      const isSubmenuVisible = document.documentElement.classList.contains('submenu-visible')
      const isExpanded = link.getAttribute('aria-expanded') === 'true'

      if (isLevel1 && isSubmenuVisible && isExpanded) {
        this.toggleMenuOverlay() // Force closing the menu on desktop
        window.clearTimeout(timeout)
        timeout = window.setTimeout(() => this.toggleSubmenu, TIMEOUT_DELAY)
      } else {
        this.toggleSubmenu(submenu, link)
      }
    }
  }

  toggleSubmenu(submenu, link) {
    for (const link of this.linksWithSubmenu) {
      link.setAttribute('aria-expanded', false)
    }

    for (const other of this.submenus) {
      other.classList.remove('expanded-menu-bar__submenu--visible')
    }

    if (link) {
      link.setAttribute('aria-expanded', true)
    }

    if (submenu) {
      submenu.classList.add('expanded-menu-bar__submenu--visible')
    }

    const slug = link ? link.getAttribute('data-slug') : ''

    this.element.classList.remove('expanded-menu-bar--search-visible')
    this.element.classList.remove('expanded-menu-bar--language-visible')

    this.searchbox.value = ''

    if (slug === 'search' && this.element.classList.contains('expanded-menu-bar--overlay-visible')) {
      this.element.classList.add('expanded-menu-bar--search-visible')
      this.searchbox.focus()
    } else if (slug === 'language' && this.element.classList.contains('expanded-menu-bar--overlay-visible')) {
      this.element.classList.add('expanded-menu-bar--language-visible')
    }

    this.setDocumentClasses()
  }

  initBackground() {
    const closeButton = this.element.querySelector('.button--close.button--menu')

    if (closeButton && this.background) {
      this.background.addEventListener('click', () => closeButton.click())
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.expanded-menu-bar')) {
    element.instance = element.instance || new ExpandedMenuBarComponent(element)
  }
})
