import Component from '../../../scripts/modules/component.js'
import SwiperWrapper from '../../../scripts/modules/swiper-wrapper.js'

const SWIPER_OPTIONS = {
  loop: false,
}

export default class CarouselHeaderComponent extends Component {
  init() {
    this.container = this.element.querySelector('.swiper-container')
    this.swiperWrapper = new SwiperWrapper(this.container, SWIPER_OPTIONS, [])
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.carousel-header')) {
    element.instance = element.instance || new CarouselHeaderComponent(element)
  }
})
