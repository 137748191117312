import Component from '../../../scripts/modules/component.js'

export default class MenuBarComponent extends Component {
  init() {
    this.menuLinksWithDropdown = [...this.element.querySelectorAll('.button--menu-link[href^="#"]')]
    this.menuDropdowns = [...this.element.querySelectorAll('.menu-bar__dropdown')]
    this.toggleOverlayButtons = [...this.element.querySelectorAll('.button--open-menu, .button--close-menu')]
    this.menuOverlay = document.getElementById('menu-overlay')
    this.toggleSearchButtons = [...this.element.querySelectorAll('.button--open-search, .button--close-search')]
    this.searchDropdown = document.getElementById('menu-search')
    this.searchInput = this.element.querySelector('.form-field--search input')
    this.submitSearchButton = this.element.querySelector('.button--search-submit')
    this.toggleLanguageButton = this.element.querySelector('.button--switch-language')
    this.languageDropdown = document.getElementById('menu-language')

    this.initMenuLinksWithDropdown()
    this.initOverlay()
    this.initSearch()
    this.initLanguageSwitch()
  }

  initMenuLinksWithDropdown() {
    for (const link of this.menuLinksWithDropdown) {
      link.addEventListener('click', event => this.toggleMenuLinkDropdown(event))
    }
  }

  toggleMenuLinkDropdown(event) {
    event.preventDefault()

    const href = event.target.getAttribute('href')

    if (!href) {
      return
    }

    const target = document.getElementById(href.split('#')[1])

    if (!target) {
      return
    }

    if (target.classList.contains('menu-bar__dropdown--visible')) {
      target.classList.remove('menu-bar__dropdown--visible')
      event.target.classList.remove('button--active')
    } else {
      for (const dropdown of this.menuDropdowns) {
        dropdown.classList.remove('menu-bar__dropdown--visible')
      }

      for (const link of this.menuLinksWithDropdown) {
        link.classList.remove('button--active')
      }

      target.classList.add('menu-bar__dropdown--visible')
      event.target.classList.add('button--active')

      this.hideSearchDropdown(event)
      this.hideLanguageDropdown(event)
    }
  }

  hideMenuLinkDropdowns(event) {
    event.preventDefault()

    for (const dropdown of this.menuDropdowns) {
      if (dropdown.classList.contains('menu-bar__dropdown--visible')) {
        const button = dropdown.parentNode.querySelector('.button--active')

        if (button) {
          button.click()
        }
      }
    }
  }

  initOverlay() {
    for (const button of this.toggleOverlayButtons) {
      button.addEventListener('click', event => this.toggleMenuOverlay(event))
    }

    // window.addEventListener('resize', event => this.hideMenuOverlay(event));
  }

  toggleMenuOverlay(event) {
    event.preventDefault()

    if (this.menuOverlay.classList.contains('menu-bar__menu-links-container--visible')) {
      window.scrollTo(0, 0)

      this.menuOverlay.classList.remove('menu-bar__menu-links-container--visible')
      document.documentElement.classList.remove('overlay-visible')

      const activeSubmenuLinks = [...this.element.querySelectorAll('.button--menu-link.button--active')]

      for (const link of activeSubmenuLinks) {
        link.click()
      }
    } else {
      this.menuOverlay.classList.add('menu-bar__menu-links-container--visible')
      document.documentElement.classList.add('overlay-visible')
    }
  }

  hideMenuOverlay(event) {
    if (this.menuOverlay.classList.contains('menu-bar__menu-links-container--visible')) {
      this.toggleMenuOverlay(event)
    }
  }

  initSearch() {
    for (const link of this.toggleSearchButtons) {
      link.addEventListener('click', event => this.toggleSearchDropdown(event))
    }

    // window.addEventListener('resize', event => this.hideSearchDropdown(event));

    this.searchInput.addEventListener('focus', () => this.searchDropdown.classList.contains('menu-bar__search-dropdown--visible') || this.toggleSearchDropdown(event)) // Auto spring open on tab focus

    for (const event of ['keyup', 'change', 'focus']) {
      this.searchInput.addEventListener(event, () => this.checkSearchValue())
    }

    this.checkSearchValue()
  }

  toggleSearchDropdown(event) {
    event.preventDefault()

    if (this.searchDropdown.classList.contains('menu-bar__search-dropdown--visible')) {
      this.searchDropdown.classList.remove('menu-bar__search-dropdown--visible')
      document.documentElement.classList.remove('search-dropdown-visible')
      this.searchInput.value = ''
    } else {
      this.searchDropdown.classList.add('menu-bar__search-dropdown--visible')
      document.documentElement.classList.add('search-dropdown-visible')
      this.searchInput.focus()

      this.hideMenuLinkDropdowns(event)
      this.hideLanguageDropdown(event)
    }
  }

  hideSearchDropdown(event) {
    if (this.searchDropdown.classList.contains('menu-bar__search-dropdown--visible')) {
      this.toggleSearchDropdown(event)
    }
  }

  checkSearchValue() {
    if (this.searchInput.value === '') {
      this.submitSearchButton.setAttribute('disabled', 'disabled')
    } else {
      this.submitSearchButton.removeAttribute('disabled')
    }
  }

  initLanguageSwitch() {
    this.toggleLanguageButton?.addEventListener('click', event => this.toggleLanguageDropdown(event))
  }

  toggleLanguageDropdown(event) {
    event.preventDefault()

    if (this.languageDropdown.classList.contains('menu-bar__language-dropdown--visible')) {
      this.languageDropdown.classList.remove('menu-bar__language-dropdown--visible')
      document.documentElement.classList.remove('language-dropdown-visible')
    } else {
      this.languageDropdown.classList.add('menu-bar__language-dropdown--visible')
      document.documentElement.classList.add('language-dropdown-visible')

      this.hideMenuLinkDropdowns(event)
      this.hideSearchDropdown(event)
    }
  }

  hideLanguageDropdown(event) {
    if (this.languageDropdown.classList.contains('menu-bar__language-dropdown--visible')) {
      this.toggleLanguageDropdown(event)
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.menu-bar')) {
    element.instance = element.instance || new MenuBarComponent(element)
  }
})
