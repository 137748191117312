import Component from '../../../scripts/modules/component.js'

export default class CookieMessageComponent extends Component {
  init() {
    this.element.addEventListener('click', event => {
      event.preventDefault()
      window.dispatchEvent(new Event('show-cookie-details'))
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.cookie-message')) {
    element.instance = element.instance || new CookieMessageComponent(element)
  }
})
