import Component from '../../../scripts/modules/component.js'

const EVENT_NAME = 'CookiebotOnAccept'

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default class TextHeaderForm extends Component {
  init() {
    // Accordion
    this.button = this.element.querySelector('.text-header-form__accordion-title')
    this.region = this.element.querySelector('.text-header-form__accordion-text')

    if (this.button && this.region) {
      this.button.addEventListener('click', () => this.toggleRegion())
    }

    // Cookies notice
    this.notice = this.element.querySelector('.text-header-form__cookies-notice')
    this.buttonCookies = this.notice ? this.notice.querySelector('.button--cookies') : null

    if (this.buttonCookies) {
      if (typeof window.Cookiebot !== 'undefined' && window.Cookiebot && !window.Cookiebot.consent.marketing && !window.Cookiebot.consent.statistics) {
        this.buttonCookies.removeAttribute('hidden')
        this.buttonCookies.addEventListener('click', event => {
          event.preventDefault()
          window.dispatchEvent(new CustomEvent('show-cookie-details'))
          window.Cookiebot.renew()
        })
      }
    }

    window.addEventListener(EVENT_NAME, () => this.checkCookie())
    this.checkCookie()
  }

  toggleRegion() {
    if (this.button.getAttribute('aria-expanded') === 'true') {
      this.region.setAttribute('aria-hidden', true)
      this.button.setAttribute('aria-expanded', false)
      this.button.classList.remove('accordion--expanded')
    } else {
      this.region.setAttribute('aria-hidden', false)
      this.button.setAttribute('aria-expanded', true)
      this.button.classList.add('accordion--expanded')

      const rect = this.region.getBoundingClientRect()
      window.scroll({ top: window.pageYOffset + rect.top - window.innerHeight / 3, left: 0, behavior: 'smooth' })
    }
  }

  onClick(event) {
    event.preventDefault()

    this.target = document.querySelector('.text-header-form__form-wrapper')

    if (!this.target) {
      // Fallback - If we don't have a target, assume that we want to scroll exactly one screen height from the top.
      window.scroll({ top: window.innerHeight, behavior: 'smooth' })
    } else {
      // Regular behavior - Scroll to the main element.
      this.target.scrollIntoView({ behavior: 'smooth' })
    }
  }

  checkCookie() {
    if (typeof window.Cookiebot === 'undefined' || !window.Cookiebot || typeof window.Cookiebot.consent === 'undefined' || !window.Cookiebot.consent || !window.Cookiebot.consent.marketing || !window.Cookiebot.consent.statistics) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    if (this.notice) {
      this.notice.removeAttribute('hidden')
    }

    this.element.classList.add('text-header-form--cookies-not-accepted')
  }

  hideCookieNotice() {
    if (this.notice) {
      this.notice.setAttribute('hidden', 'hidden')
    }

    this.element.classList.remove('text-header-form--cookies-not-accepted')

    window.removeEventListener(EVENT_NAME, () => this.checkCookie())
    this.initForm()
  }

  initForm() {
    const scriptDataSrcElements = [...document.querySelectorAll('script[data-marketingdynamics-src]')]
    scriptDataSrcElements.forEach(scriptEl => {
      if (scriptEl.hasAttribute('data-marketingdynamics-src')) {
        scriptEl.setAttribute('src', scriptEl.getAttribute('data-marketingdynamics-src'))
        scriptEl.removeAttribute('data-marketingdynamics-src')
      }
    })

    this.waitForForm().then(() => {
      this.form.addEventListener('submit', event => window.dispatchEvent(new CustomEvent('text-header-form:form-submit', { detail: { originalEvent: event, element: this.element, form: this.form } })))

      const isSwapped = this.element.classList.contains('text-header-form--form-first')
      const checkwidth = window.matchMedia('(max-width: 700px)')

      if (!isSwapped && !checkwidth.matches) {
        this.formcontainer = this.element.querySelector('.text-header-form__form-wrapper-inner')
        this.textcontainer = document.querySelector('.text-header-form__text-wrapper')
        this.textcontainer.style.minHeight = this.formcontainer.offsetHeight - 100 + 'px'
      }
    })
  }

  // Since we load content asynchronously, we don't know when it arrives, therefor we poll until we see it.
  async waitForForm() {
    let t = 100
    this.form = this.element.querySelector('form')

    while (!this.form) {
      await sleep(t)

      this.form = this.element.querySelector('form')
      t *= 2
    }

    return this.form
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.text-header-form')) {
    element.instance = element.instance || new TextHeaderForm(element)
  }
})
