import Component from '../../../scripts/modules/component.js'

export default class FlipbaseComponent extends Component {
  init() {
    this.container = this.element.querySelector('.flipbase__container')

    if (!this.container) {
      return
    }

    this.embedUrl = this.container.getAttribute('data-embed-url') || ''

    if (!this.embedUrl) {
      return
    }

    this.iframe = null

    if (typeof window.Cookiebot === 'undefined' || !window.Cookiebot || typeof window.Cookiebot.consent === 'undefined' || !window.Cookiebot.consent || !window.Cookiebot.consent.marketing) {
      this.element.classList.add('flipbase--cookies-not-accepted')
      window.addEventListener('CookiebotOnAccept', () => this.initEmbed())

      return
    }

    this.initEmbed()
  }

  initEmbed() {
    if (typeof window.Cookiebot === 'undefined' || !window.Cookiebot || typeof window.Cookiebot.consent === 'undefined' || !window.Cookiebot.consent || !window.Cookiebot.consent.marketing) {
      return
    }

    window.removeEventListener('CookiebotOnAccept', () => this.initEmbed())

    this.element.classList.remove('flipbase--cookies-not-accepted')
    this.iframe = document.createElement('iframe')
    this.iframe.setAttribute('src', this.embedUrl)
    this.iframe.setAttribute('allowfullscreen', '')
    this.container.appendChild(this.iframe)

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'flipbase.loaded' })
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.flipbase')) {
    element.instance = element.instance || new FlipbaseComponent(element)
  }
})
