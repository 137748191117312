import throttle from 'lodash.throttle'

import Component from '../../../scripts/modules/component.js'

export default class ActionBarComponent extends Component {
  init() {
    this.buttonToTargetElement = this.element.querySelector('.button[href^="#"]')
    this.targetElement = this.buttonToTargetElement ? document.querySelector(this.buttonToTargetElement.getAttribute('href')) : null
    this.stickyMenuEnabled = false

    if (this.targetElement) {
      this.buttonToTargetElement.addEventListener('click', event => {
        event.preventDefault()
        window.scroll({ left: window.pageXOffset, top: this.targetElement.offsetTop, behavior: 'smooth' })
      })
    }

    this.initScroll()
  }

  initScroll() {
    const throttledScroll = throttle(() => window.requestAnimationFrame(() => this.updateScroll()), 10, { leading: true, trailing: true })

    window.addEventListener('scroll', () => throttledScroll(), { passive: true })
    window.setTimeout(() => this.updateScroll(), 150)
  }

  updateScroll() {
    const appleUnderscroll = window.pageYOffset < 0
    const aboveTheFold = window.pageYOffset < window.innerHeight / 1.5
    const targetTopInView = this.targetElement && window.pageYOffset >= this.targetElement.offsetTop
    const targetBottomInView = this.targetElement && window.pageYOffset <= this.targetElement.offsetTop + this.targetElement.offsetHeight
    const targetInView = targetTopInView && targetBottomInView

    if (appleUnderscroll || aboveTheFold || targetInView) {
      this.element.classList.remove('action-bar--visible')

      // if menu is set to sticky
      if (this.stickyMenuEnabled) {
        document.documentElement.classList.remove('action-bar-is-visible')
      }
    } else {
      this.element.classList.add('action-bar--visible')

      // if menu is set to sticky
      if (document.documentElement.classList.contains('menu-is-sticky')) {
        this.stickyMenuEnabled = true
        document.documentElement.classList.add('action-bar-is-visible')
      }
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.action-bar')) {
    element.instance = element.instance || new ActionBarComponent(element)
  }
})
