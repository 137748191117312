import Component from '../../../scripts/modules/component.js'

const EVENT_NAME = 'CookiebotOnAccept'

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default class SkinnedContent extends Component {
  init() {
    // Cookies notice
    this.notice = this.element.querySelector('.skinned-content__cookies-notice')
    this.buttonCookies = this.notice ? this.notice.querySelector('.button--cookies') : null

    if (this.buttonCookies) {
      if (typeof window.Cookiebot !== 'undefined' && window.Cookiebot && !window.Cookiebot.consent.marketing && !window.Cookiebot.consent.statistics) {
        this.buttonCookies.removeAttribute('hidden')
        this.buttonCookies.addEventListener('click', event => {
          event.preventDefault()
          window.dispatchEvent(new CustomEvent('show-cookie-details'))
          window.Cookiebot.renew()
        })
      }
    }

    window.addEventListener(EVENT_NAME, () => this.checkCookie())
    this.checkCookie()
  }

  checkCookie() {
    if (typeof window.Cookiebot === 'undefined' || !window.Cookiebot || typeof window.Cookiebot.consent === 'undefined' || !window.Cookiebot.consent || !window.Cookiebot.consent.marketing || !window.Cookiebot.consent.statistics) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    if (this.notice) {
      this.notice.removeAttribute('hidden')
    }

    this.element.classList.add('skinned-content--cookies-not-accepted')
  }

  hideCookieNotice() {
    if (this.notice) {
      this.notice.setAttribute('hidden', 'hidden')
    }

    this.element.classList.remove('skinned-content--cookies-not-accepted')

    window.removeEventListener(EVENT_NAME, () => this.checkCookie())
    this.initForm()
  }

  initForm() {
    const scriptDataSrcElements = [...document.querySelectorAll('script[data-marketingdynamics-src]')]
    scriptDataSrcElements.forEach(scriptEl => {
      if (scriptEl.hasAttribute('data-marketingdynamics-src')) {
        scriptEl.setAttribute('src', scriptEl.getAttribute('data-marketingdynamics-src'))
        scriptEl.removeAttribute('data-marketingdynamics-src')
      }
    })

    this.waitForForm().then(() => this.form.addEventListener('submit', event => window.dispatchEvent(new CustomEvent('skinned-content:form-submit', { detail: { originalEvent: event, element: this.element, form: this.form } }))))
  }

  // Since we load content asynchronously, we don't know when it arrives, therefor we poll until we see it
  async waitForForm() {
    let t = 100
    this.form = this.element.querySelector('form')

    while (!this.form) {
      await sleep(t)

      this.form = this.element.querySelector('form')
      t *= 2
    }

    return this.form
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.skinned-content')) {
    element.instance = element.instance || new SkinnedContent(element)
  }
})
