import GridManager from '../../../scripts/components/grid-manager.js'

export default class LinkBarComponent extends GridManager {
  init() {
    super.init()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.link-bar')) {
    element.instance = element.instance || new LinkBarComponent(element)
  }
})
